import { graphql } from 'gatsby';
import React from 'react';
import { renderDynamicComponents } from '../components';
import Layout from '../modules/layout';
import SEO from '../modules/seo';

const PageTemplate = ({ data, pageContext }) => {
  const {
    microcopy,
    contentfulPage: { 
      components,
      seo,
      seoTitle,
      seoDescription,
      ogImage,
      metaTags,
      structuredData,
     }
  } = data;
  const { settings, node_locale, breadcrumbs, title, slug } =
    pageContext?.localizedNode;
  settings.breadcrumbs = breadcrumbs;
  settings.translatedPageSlug = pageContext?.translatedPageSlug;

  settings.microcopy = {};
  microcopy.nodes.forEach((node) => {
    settings.microcopy[node.key] = node.value;
  });
  return (
    <Layout settings={settings}>
      <SEO
        title={`${seoTitle || seo?.title || title} - ${settings.siteName}`}
        description={seoDescription || seo?.description || settings.siteDescription}
        author={settings.siteAuthor}
        structuredData={structuredData}
        lang={node_locale}
        globalCss={settings?.globalCss}
        metaTags={metaTags || seo?.metaTags}
        ogImage={ogImage || seo?.ogImage}
        slug={slug}
      />
      {components && renderDynamicComponents(components, node_locale)}
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment button on ContentfulButton {
    internal {
      type
    }
    title
    type
    link {
      ... on Node {
        ...link
      }
    }
  }

  fragment color on ContentfulColor {
    title
    baseColor
    brightness
  }

  fragment link on ContentfulLinkExternalContentfulLinkInternalUnion {
    ... on ContentfulLinkInternal {
      title
      target {
        title
        fullSlug
      }
      anchor
    }
    ... on ContentfulLinkExternal {
      title
      url: target
    }
  }

  fragment imageFixed on ContentfulFixed {
    src
    srcSet
    srcSetWebp
    srcWebp
    height
    width
  }

  fragment imageFluid on ContentfulFluid {
    base64
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }

  fragment imageMeta on ContentfulAsset {
    title
    description
    file {
      url
    }
  }
  fragment contentTypes on Node {
    __typename
    id
    internal {
      type
    }
    ... on ContentfulComponentTeaserPictureCta {
      title
      text
      headlineH1
      image {
        ...imageMeta
        fluid(quality: 80) {
          ...imageFluid
        }
      }
      colorContainer: color {
        ... on ContentfulColor {
          ...color
        }
      }
      button {
        ... on ContentfulButton {
          ...button
        }
      }
    }
    ... on ContentfulComponentCode {
      title
      code {
        code
      }
    }
    ... on ContentfulComponentCubeNavigation {
      title
      links {
        ... on Node {
          ...link
        }
      }
    }
    ... on ContentfulComponentFloatingContent {
      title
      headline
      textContent: subtitle {
        subtitle
      }
      backgroundImage {
        title
        fluid(quality: 80) {
          ...imageFluid
        }
      }
      topRow {
        ... on ContentfulComponentFloatingContentColumn {
          title
          content {
            content
          }
          link {
            ... on Node {
              ...link
            }
          }
        }
      }
      bottomRow {
        ... on ContentfulComponentFloatingContentColumn {
          title
          content {
            content
          }
          link {
            ... on Node {
              ...link
            }
          }
        }
      }
      links {
        ... on ContentfulButton {
          ...button
        }
      }
    }
    ... on ContentfulComponentHero {
      title
      titleHighlight
      overlap
      subtitle {
        subtitle
      }
      button {
        ...button
      }
      image {
        ...imageMeta
        file {
          url
          contentType
        }
        fixed(width: 1920, quality: 80) {
          ...imageFixed
        }
        fluid(quality: 80, maxWidth: 1920) {
          ...imageFluid
        }
      }
    }

    ... on ContentfulComponentLiveStream {
      title
      video
      chat
      textLeft {
        raw
      }
      textRight {
        raw
      }
      buttons {
        ... on Node {
          ... on ContentfulButton {
            ...button
          }
        }
      }
    }

    ... on ContentfulComponentSocialShare {
      url
      displayBreadcrumb
    }
    ... on ContentfulComponentFlipCardContainer {
      ...flipCards
    }
    ... on ContentfulComponentFormElements {
      title
      content {
        content
      }
      script {
        script
      }
      image {
        ...imageMeta
        fixed(width: 150, quality: 80) {
          ...imageFixed
        }
        fluid(quality: 80) {
          ...imageFluid
        }
      }
    }
    ... on ContentfulComponentDiagonalBackgroundContainer {
      title
      colorContainer: color {
        ... on ContentfulColor {
          ...color
        }
      }
      links {
        ... on Node {
          ...button
        }
      }
      columns {
        ... on ContentfulComponentDiagonalBackgroundContent {
          title
          content {
            content
          }
        }
      }
      images {
        title
        fixed(width: 150, quality: 80) {
          ...imageFixed
        }
        fluid(quality: 80) {
          ...imageFluid
        }
      }
    }
    ... on ContentfulComponentTextVideoContainer {
      title
      doNotShowTitle
      headline
      content {
        content
      }
      colorContainer: color {
        ... on ContentfulColor {
          ...color
        }
      }
      items {
        ... on ContentfulComponentTextVideo {
          title
          doNotShowTitle
          content {
            content
          }
          video
          image {
            ...imageMeta
            fluid(quality: 80) {
              ...imageFluid
            }
          }
          orientation
          button {
            ...button
          }
          videoConsentImage {
            fixed(quality: 80) {
              ...imageFixed
            }
          }
        }
      }
    }
    ... on ContentfulComponentGallery {
      title
      showTitle
      headline
      content {
        content
      }
      buttons {
        ... on ContentfulButton {
          ...button
        }
      }
      colorContainer: color {
        ... on ContentfulColor {
          ...color
        }
      }
      items {
        title
        content {
          content
        }
        imageFullscreen
        caption
        image {
          ...imageMeta
          fixed(quality: 80) {
            ...imageFixed
          }
          fluid(quality: 80, maxWidth: 1920) {
            ...imageFluid
          }
        }
        link {
          ... on Node {
            ...link
          }
        }
      }
      isCarousel
      display1Column
      displayJustImage
      infinite
    }
    ... on ContentfulComponentRichText {
      title
      showTitle
      richTextHeadline: headline {
        headline
      }
      content {
        raw
      }
    }
    ... on ContentfulComponentCircleContainer {
      title
      doNotShowTitle
      circleContainerHeadline: headline {
        headline
      }
      content {
        content
      }
      circles {
        ... on ContentfulComponentCircle {
          title
          number
          symbol
          isPercent
        }
      }
      button {
        ...button
      }
    }
    ... on ContentfulComponentArticle {
      image {
        ...imageMeta
      }
      caption
      date
      title
      teaser {
        teaser
      }
      main {
        ... on Node {
          id
          internal {
            type
          }
          ... on ContentfulComponentRichText {
            title
            showTitle
            richTextHeadline: headline {
              headline
            }
            content {
              raw
            }
          }
          ... on ContentfulComponentCode {
            title
            code {
              code
            }
          }
          ... on ContentfulComponentCircleContainer {
            title
            doNotShowTitle
            circleContainerHeadline: headline {
              headline
            }
            content {
              content
            }
            circles {
              ... on ContentfulComponentCircle {
                title
                number
                symbol
                isPercent
              }
            }
            button {
              ...button
            }
          }
          ... on ContentfulComponentFlipCardContainer {
            title
            doNotShowTitle
            headline
            content {
              content
            }
            cards {
              ... on ContentfulComponentFlipCard {
                title
                content {
                  content
                }
                isFlipped
                link {
                  ...link
                }
                image {
                  file {
                    url
                  }
                  title
                  fixed(width: 150, quality: 80, toFormat: PNG) {
                    ...imageFixed
                  }
                  fluid(quality: 80) {
                    ...imageFluid
                  }
                }
                color
              }
            }
          }
          ... on ContentfulComponentGallery {
            title
            showTitle
            headline
            content {
              content
            }
            buttons {
              ... on ContentfulButton {
                ...button
              }
            }
            colorContainer: color {
              ... on ContentfulColor {
                ...color
              }
            }
            items {
              title
              content {
                content
              }
              caption
              imageFullscreen
              image {
                ...imageMeta
                fixed(quality: 80) {
                  ...imageFixed
                }
                fluid(quality: 80, maxWidth: 1920) {
                  ...imageFluid
                }
              }
              link {
                ... on Node {
                  ...link
                }
              }
            }
            isCarousel
            display1Column
            displayJustImage
            infinite
          }
          ... on ContentfulComponentTeaserPictureCta {
            title
            text
            headlineH1
            image {
              ...imageMeta
              fluid(quality: 80) {
                ...imageFluid
              }
            }
            colorContainer: color {
              ... on ContentfulColor {
                ...color
              }
            }
            button {
              ... on ContentfulButton {
                ...button
              }
            }
          }
          ... on ContentfulComponentTextVideoContainer {
            title
            doNotShowTitle
            headline
            content {
              content
            }
            colorContainer: color {
              ... on ContentfulColor {
                ...color
              }
            }
            items {
              ... on ContentfulComponentTextVideo {
                title
                doNotShowTitle
                content {
                  content
                }
                video
                image {
                  ...imageMeta
                  fluid(quality: 80) {
                    ...imageFluid
                  }
                }
                orientation
                button {
                  ...button
                }
                videoConsentImage {
                  fixed(quality: 80) {
                    ...imageFixed
                  }
                }
              }
            }
          }
        }
      }
      footerTitle
      authors {
        ... on ContentfulComponentAuthor {
          image {
            ...imageMeta
          }
          name
          position
          company
        }
      }
      footerText: text {
        text
      }
      button {
        ...button
      }
      node_locale
    }
  }

  fragment structuredDataArticle on ContentfulStructuredDataArticle {
    internal {
      type
    }
    name
    headline
    abstract
    articleSection
    author
    contributor
    datePublished
    keywords
    url
  }

  fragment structuredDataCorporation on ContentfulStructuredDataCorporation {
    internal {
      type
    }
    name
    brand
    logo {
      file {
        url
      }
    }
    description
    url
    email
    legalName
    taxId
    address
    telephone
    founder
  }

  fragment structuredDataBusinessEvent on ContentfulStructuredDataBusinessEvent {
    internal {
      type
    }
    name
    description
    startDate
    endDate
    location
    organizer
    attendee
    eventAttendanceMode
    url
  }

  fragment structuredDataIndividualProduct on ContentfulStructuredDataIndividualProduct {
    internal {
      type
    }
    name
    description
    category
    brand
    manufacturer
    audience
    countryOfOrigin
    url
  }

  query PageLocalQuery($id: String!, $node_locale: String!) {
    microcopy: allContentfulMicrocopy(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        node_locale
        key
        value
      }
    }
    contentfulPage(id: { eq: $id }) {
      id
      title
      node_locale
      components {
        ...contentTypes
      }
      seoTitle
      seoDescription
      ogImage {
        contentful_id
        title
        description
        file {
          url
        }
      }
      metaTags
      structuredData {
        ... on Node {
          ... on ContentfulStructuredDataArticle {
            ...structuredDataArticle
          }
          ... on ContentfulStructuredDataCorporation {
            ...structuredDataCorporation
          }
          ... on ContentfulStructuredDataBusinessEvent {
            ...structuredDataBusinessEvent
          }
          ... on ContentfulStructuredDataIndividualProduct {
            ...structuredDataIndividualProduct
          }
        }
      }
      seo {
        id
        title
        description
        keywords
        structuredData {
          ... on Node {
            ... on ContentfulStructuredDataArticle {
              ...structuredDataArticle
            }
            ... on ContentfulStructuredDataCorporation {
              ...structuredDataCorporation
            }
            ... on ContentfulStructuredDataBusinessEvent {
              ...structuredDataBusinessEvent
            }
            ... on ContentfulStructuredDataIndividualProduct {
              ...structuredDataIndividualProduct
            }
          }
        }
        ogImage {
          contentful_id
          title
          description
          file {
            url
          }
        }
        metaTags {
          metaTags
        }
        scripts {
          scripts
        }
        styles {
          styles
        }
        searchResultImage {
          contentful_id
          title
          description
          file {
            url
          }
        }
        node_locale
      }
    }
  }
`;

export default PageTemplate;
